import React, { PropsWithChildren } from 'react'
import { useWalletContext } from '../hooks/useWallet'
import Button from './Button'

type ButtonProps = {
  color?: string | undefined
  bgColor?: string | undefined
}

const WalletConnect = (props: PropsWithChildren<ButtonProps>) => {
  const { children, color, bgColor } = props
  const { connected, connectWallet, disconnectWallet } = useWalletContext()

  const btnClickHandler = () => {
    if (connected)
      disconnectWallet?.()
    else
      connectWallet?.()
  }

  return (
    <Button
      onClick={btnClickHandler}
      color={color || 'white'}
      bgColor={bgColor || '#27aae1'}
    >
      {connected ? 'Disconnect' : 'Connect to Pera Wallet'}
    </Button>
  )
}

export default WalletConnect
