import React, { useState } from 'react'

const BuyNeb = () => {
  const [algoAmount, setAlgoAmount] = useState<number>()

  return (
    <div className='min-h-screen items-center flex justify-center'>
      <div className='flex flex-col min-w-[500px] p-10 space-y-5 rounded-xl bg-[#0A252FE6]'>
        <div className='space-y-1'>
          <div className='text-gray-100/50'>Amount</div>
          <input
            onChange={(e) => setAlgoAmount(parseFloat(e.target.value))}
            value={algoAmount ?? ''}
            className='rounded w-full px-2 py-1 outline-none border-none'
            placeholder='Enter amount of Algo spend'
          />
        </div>
        <div className='bg-white h-[140px] rounded flex flex-col items-center p-4 space-y-4'>
          <div>You will receive:</div>
          <div>{`${algoAmount ?? 0} NEB tokens`}</div>
        </div>
        <div className='flex items-center justify-center'>
          <button className='px-4 py-1 rounded bg-white'>BUY</button>
        </div>
      </div>
    </div>
  )
}

export default BuyNeb
