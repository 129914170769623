import React, { createContext, useState, useContext } from 'react'

export const ModalContext = createContext()

// eslint-disable-next-line react/prop-types
export const ModalProvider = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const openModal = () => {
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  return <ModalContext.Provider value={{ isModalOpen, openModal, closeModal }}>{children}</ModalContext.Provider>
}

export const useModalContext = () => useContext(ModalContext)
