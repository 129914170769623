/* eslint-disable quotes */
/* eslint-disable react/jsx-key */
import React, { ReactEventHandler, PropsWithChildren, PropsWithoutRef } from 'react'

import StampingArt from '../assets/Stamping_art.png'
import { useModalContext } from '../StateContext'
import HowToStampModal from '../components/HowToStampModal'

// import MyAlgoWallet from '@randlabs/myalgo-connect'

// import { PeraWalletConnect } from '@perawallet/connect'

// import WalletConnect from '@walletconnect/client';
// import QRCodeModal from 'algorand-walletconnect-qrcode-modal';
import Button from '../components/Button'
import Modal from '../components/Modal'
import algosdk, { Algodv2, SuggestedParams, TransactionSigner, Transaction } from 'algosdk'

import Logo from '../assets/Cater-DAO-Logo-draft_2.png'
import NoTixImage from '../images/noTixImage.png'
import imgAlgoGemLogo from '../images/logo.f3a6bd24.svg'
import imgBrokenRobot from '../assets/BrokenRobot.png'
import imgDroppingSoonBG from '../images/dropping_soon_graphic.png'
import imgRetroBG from '../assets/retroBg.png'

import axios from 'axios'
import { useLocalStorage } from 'usehooks-ts'
import { BoxLoading } from 'react-loadinggg'

import busAbi from '../assets/contract.json'
import { AssetInfo } from '@algo-builder/types-algosdk'
import TopNav from '../components/TopNav'
import { formatDiagnosticsWithColorAndContext } from 'typescript'

import Base58 from 'base-58'
import { CID } from 'multiformats/cid'
import * as digest from 'multiformats/hashes/digest'
import * as mfsha2 from 'multiformats/hashes/sha2'
// import { CIDVersion } from 'multiformats/types/src/cid'
// import * as XX from 'multiformats'
import { Version } from 'multiformats'
import AsyncImage from '../components/AsyncImage'
import IpfsImage from '../components/IpfsImage'

import { useWalletContext } from '../hooks/useWallet'
import ButtonConnectWallet from '../components/ButtonConnectWallet'
import { Z_VERSION_ERROR } from 'zlib'


// *****************************
// * Types etc
// *****************************
type accAssets = {
  'asset-id': string
  amount: number
}

type KVPair = {
  [key: string]: string
}
type GSValues = {
  bytes: string
  type: number
  uint: number
}

type UserNFTRec = {
  index: number
  params: {
    url: string
    reserve: string
  }
}

const isFulfilled = <T,>(p: PromiseSettledResult<T>): p is PromiseFulfilledResult<T> => p.status === 'fulfilled'
const isRejected = <T,>(p: PromiseSettledResult<T>): p is PromiseRejectedResult => p.status === 'rejected'

// *****************************
// * Various global vals
// *****************************
// TESTNET:
// const peraWallet = new PeraWalletConnect({chainId:416002});
// MAINNET:
// const peraWallet = new PeraWalletConnect({chainId:4160});

// const peraWallet = new PeraWalletConnect()

// TESTNET:
// const defaultBusTikID = 167821197
// const defaultScAppID = {
//   index: 211416460,
//   addr: 'T656JI35JBSSUUFFW4PMHW6JCS6X3RLXTH5KIGK472RXRCAPCT7HQQKCBM'
// }

// MAINNET:
const defaultBusTikID = 1080669947
const defaultScAppID = {
  index: 1105877276,
  addr: 'AYJQBJT7E2KXCRZIIJGXQLKEHEGQM7MKRRZVFGPYLLD7I26PDIP7L3Z3AE',
}

// LOCAL:
// const defaultBusTikID = 175
// const defaultScAppID = {
//   index: 728,
//   addr: 'UOAXDEFFBHYG5DGRG64RVTHY4QLY5CZDERVZM6ZOGNSKW7ZWYV5OLDVDHQ'
// }

const MIN_COST_SPECIAL = 5001000
const MIN_COST_NORMAL = 10100

// UI States:
const UI_STATES = {
  NOT_CONNECTED: -1,
  HOME: 1,
  CHOOSE_NUMBER: 2,
  WAIT: 3,
  // CHOOSE_WAIT: 3,
  PENDING_STAMP: 4,
  CLAIM_STAMP: 5,
  // CLAIM_WAIT: 6,
  SUCCESS: 7,
  ERROR: 8,
  REVEAL: 9,
}

export const ARC3_NAME_SUFFIX = '@arc3'
export const ARC3_URL_SUFFIX = '#arc3'
export function resolveProtocol(url: string, reserveAddr: string): string {
  if (url.endsWith(ARC3_URL_SUFFIX)) url = url.slice(0, url.length - ARC3_URL_SUFFIX.length)

  const chunks = url.split('://')
  // console.log('resolve protocol:', url)
  // console.log(chunks)

  // Check if prefix is template-ipfs and if {ipfscid:..} is where CID would normally be
  if (chunks[0] === 'template-ipfs' && chunks[1].startsWith('{ipfscid:')) {
    // Look for something like: template:ipfs://{ipfscid:1:raw:reserve:sha2-256} and parse into components
    chunks[0] = 'ipfs'
    const cidComponents = chunks[1].split(':')
    if (cidComponents.length !== 5) {
      // give up
      console.log('unknown ipfscid format')
      return url
    }
    const [, cidVersion, cidCodec, asaField, cidHash] = cidComponents

    // const cidVersionInt = parseInt(cidVersion) as CIDVersion
    if (cidHash.split('}')[0] !== 'sha2-256') {
      console.log('unsupported hash:', cidHash)
      return url
    }
    if (cidCodec !== 'raw' && cidCodec !== 'dag-pb') {
      console.log('unsupported codec:', cidCodec)
      return url
    }
    if (asaField !== 'reserve') {
      console.log('unsupported asa field:', asaField)
      return url
    }
    let cidCodecCode
    if (cidCodec === 'raw') {
      cidCodecCode = 0x55
    } else if (cidCodec === 'dag-pb') {
      cidCodecCode = 0x70
    }

    // get 32 bytes Uint8Array reserve address - treating it as 32-byte sha2-256 hash
    const addr = algosdk.decodeAddress(reserveAddr)
    const mhdigest = digest.create(mfsha2.sha256.code, addr.publicKey)

    // const cid = CID.create(parseInt(cidVersion) as CIDVersion, cidCodecCode as number, mhdigest)
    const cid = CID.create(parseInt(cidVersion) as Version, cidCodecCode as number, mhdigest)
    // console.log('switching to id:', cid.toString())
    chunks[1] = cid.toString() + '/' + chunks[1].split('/').slice(1).join('/')
    // console.log('redirecting to ipfs:', chunks[1])
  }

  // No protocol specified, give up
  if (chunks.length < 2) return url

  // Switch on the protocol
  switch (chunks[0]) {
    case 'ipfs': // Its ipfs, use the configured gateway
      // return conf[activeConf].ipfsGateway + chunks[1]
      return 'http://213.255.247.21:8080/ipfs/' + chunks[1]
    case 'https': // Its already http, just return it
      return url
    // TODO: Future options may include arweave or algorand
  }

  return url
}

const BusTix2 = () => {
  const { openModal } = useModalContext()
  const { wallet:peraWallet, address:accountAddress, connected:isConnectedToPeraWallet, connectWallet, disconnectWallet } = useWalletContext()
  // const [accountAddress, setAccountAddress] = React.useState<string | null>(null)
  // const isConnectedToPeraWallet = !!accountAddress

  const [uiState, setUiState] = React.useState(UI_STATES.NOT_CONNECTED)
  const [uiStatePrv, setUiStatePrv] = React.useState(UI_STATES.NOT_CONNECTED)
  const [waitMessage, setWaitMessage] = React.useState({ title: 'Please wait', msg: 'Working on it' })
  const [cancelled, setCancelled] = React.useState(false)

  // App related:
  const [appInfo, setAppInfo] = React.useState<any>({})
  const [appAccInfo, setAppAccInfo] = React.useState<any>({})

  // const [ appCreatedAssets, setAppCreatedAssets ] = React.useState<Array<any>>([])

  // const [ tempTixAsid, setTempTixAsid ] = useLocalStorage<number>('asid', -1)
  const [tempTixAsid, setTempTixAsid] = React.useState<number | undefined>()

  // Account related:
  const [accInfo, setAccInfo] = React.useState<any>()
  const [algodClient, setAlgodClient] = React.useState<Algodv2>()
  const [userBusTickets, setUserBusTickets] = React.useState([])

  // modal:
  const [showStampModal, setShowStampModal] = React.useState(false)
  const [showNotEnoughBal, setShowNotEnoughBal] = React.useState(false)

  const [specialNumbers, setSpecialNumbers] = React.useState<Array<string>>([])
  const [specialNumber, setSpecialNumber] = React.useState<string>('')

  const [pendingStamps, setPendingStamps] = React.useState<Array<{ address: string; nftid: number }>>([])
  const [pendingStamp, setPendingStamp] = React.useState<{ address: string; nftid: number }>()

  const [stampTikWaiting, setStampTikWaiting] = React.useState(false)
  const [responseError, setResponseError] = React.useState<{ error: string; status: number; name: string }>()

  const changeUIState = (newState: number) => {
    setUiStatePrv(uiState)
    setUiState(newState)
  }

  const uiStateGoBack = () => {
    // console.log('STATE Going back to', uiStatePrv)
    setUiState(uiStatePrv)
  }

  React.useEffect(() => {
    // Re-connect an older Pera wallet session:
    // Reconnect to the session when the component is mounted
    // peraWallet
    //   .reconnectSession()
    //   .then((accounts) => {
    //     // Setup the disconnect event listener
    //     peraWallet.connector?.on('disconnect', handleDisconnectWalletClick)

    //     if (peraWallet.isConnected && accounts.length) {
    //       // console.log('Setting account Address')
    //       setAccountAddress(accounts[0])

    //       if (uiState == UI_STATES.NOT_CONNECTED) changeUIState(UI_STATES.HOME)
    //       // setUiState(UI_STATES.HOME)
    //     }
    //   })
    //   .catch((error) => {
    //     console.log(error)
    //   })
  }, [])

  React.useEffect(() => {
    // Get the available numbers:
    loadSCDetails()
  }, [algodClient])

  React.useEffect(() => {
    if (accountAddress) {
      console.log('accountAddress Set', accountAddress)

      loadAccountInfoUI()

      // Get the available numbers:
      // loadSCDetails()

      // const pk = algosdk.decodeAddress(accountAddress)
      // // console.log('DECODED', pk)
      // const addr = algosdk.encodeAddress(pk.publicKey)
      // console.log(accountAddress, addr)

      // console.log('BuffLength', pk.publicKey.byteLength)
      // console.log('PK Buffer', pk.publicKey.buffer.toString())
      // console.log(algosdk.en)

      if (uiState == UI_STATES.NOT_CONNECTED) 
        changeUIState(UI_STATES.HOME)
    } else {
      // No address so not connected:
      setUiState(UI_STATES.HOME)
      setUserBusTickets([])
    }
  }, [accountAddress])

  React.useEffect(() => {
    if (!pendingStamps) return

    // Set state:
    const pendingStamp = pendingStamps.find((pa) => pa.address == accountAddress || '')
    if (isConnectedToPeraWallet && pendingStamp != null) {
      setPendingStamp(pendingStamp)
      // setUiState(UI_STATES.PENDING_STAMP)
      changeUIState(UI_STATES.PENDING_STAMP)
    }
  }, [pendingStamps])

  React.useEffect(() => {
    if (!appAccInfo || !appAccInfo['created-assets'] || !accInfo || !accInfo.assets) return

    // Get user NFTs:

    const appAssets = appAccInfo['created-assets']
    const userAssets = accInfo.assets

    // console.log('BOTH', appAssets, userAssets)

    const usersNFTs = userAssets
      .filter((uASA: { 'asset-id': number }) =>
        appAssets.find(
          (aASA: { index: number; params: { 'unit-name': string } }) =>
            aASA.index == uASA['asset-id'] && aASA.params['unit-name'] == 'BTIX',
        ),
      )
      .map((uNFT: { 'asset-id': number }) => ({
        ...uNFT,
        ...appAssets.find((aASA: { index: number; params: { 'unit-name': string } }) => aASA.index == uNFT['asset-id']),
      }))

    console.log('USER BUS TICKETS', usersNFTs)
    setUserBusTickets(usersNFTs)
  }, [appAccInfo, accInfo])

  const loadAccountInfoUI: () => void = () => {
    // if (accountAddress){
    // const token = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
    // const server = 'http://127.0.0.1';
    // const port = 4001;

    // AlgoNode:
    // const token = '';
    // const server = 'https://testnet-api.algonode.network/';
    // const port = 443;
    // Mainnet:
    const token = ''
    const server = 'https://mainnet-api.algonode.cloud'
    const port = 443

    const client = new algosdk.Algodv2(token, server, port)

    setAlgodClient(client)

    // console.log('getting client status')
    ;(async () => {
      // console.log(await client.status().do());

      // check balance;
      const acctInfo = await client.accountInformation(accountAddress || '').do()
      // console.log(`Account balance: ${acctInfo.amount} microAlgos`);
      // console.log('acctInfo', acctInfo)

      setAccInfo(acctInfo)

      // Decode address:
      // algosdk.decodeAddress
    })().catch((e) => {
      console.log(e)
    })
    // }
  }

  const loadSCDetails: () => void = async () => {
    //
    if (!algodClient) return

    Promise.allSettled([
      algodClient.getApplicationByID(defaultScAppID.index).do(),
      algodClient.accountInformation(defaultScAppID.addr).do(),
    ]).then((results) => {
      // const appInfo = resp[0]?.value || {}
      // const appInfo = resp[0].value

      const fulfilledValues = results.filter(isFulfilled).map((p) => p.value)
      const rejectedReasons = results.filter(isRejected).map((p) => p.reason)

      if (rejectedReasons.length > 0) {
        // set a global error, or try again later?
        console.log('loadSCDetails ERROR')
        return
      }

      const appInfo = fulfilledValues[0]
      const appAccInfo = fulfilledValues[1]

      const globalState = appInfo.params['global-state']

      // dump out all created assets:
      const specNumsTest = [
        '000000',
        '000001',
        '000010',
        '000100',
        '001000',
        '010000',
        '100000',
        '100001',
        '001100',
        '000069',
        '000420',
        '690420',
        '123456',
        '111111',
        '654321',
        '123321',
        '110011',
        '111111',
        '222222',
        '333333',
        '444444',
        '555555',
        '666666',
        '777777',
        '888888',
        '999999',
      ]
      // console.log(appAccInfo)
      const bTixCreatedAssets = appAccInfo['created-assets'].filter(
        (ass: { params: { 'unit-name': string } }) => ass.params['unit-name'] == 'BTIX',
      )

      bTixCreatedAssets.forEach((ass: { index: number; params: { url: string } }) => {
        // console.log(`${ass.index}: ${ass.params.url}`)

        const parts = ass.params.url.split('/')
        // console.log(parts)

        const tikNum = parts[parts.length - 1].split('.')[0]
        // console.log(tikNum)

        // if (specNumsTest.includes(tikNum.padStart(6, '0'))) {
        //   console.log('SPECIAL:', tikNum)
        // }
      })

      setAppInfo(appInfo)
      setAppAccInfo(appAccInfo)

      /* GET AVAILABLE SPECIAL NUMBERS FROM GLOBAL STATE */
      // console.log(globalState)
      const specNums = globalState
        .filter((gs: KVPair) => gs.key.startsWith('c3BlY2l'))
        .map((gs: KVPair) => ({
          key: Buffer.from(gs.key, 'base64').toString().substring(14),
          value: (gs.value as unknown as GSValues).uint,
        }))
        .filter((gs: KVPair) => gs.value == '1')
        .sort((a: KVPair, b: KVPair) => (parseInt(a.key) > parseInt(b.key) ? 1 : -1))
        .reduce((acc: Array<string>, gs: KVPair) => {
          acc.push(gs.key.padStart(6, '0'))
          return acc
        }, [])

      setSpecialNumbers(specNums)
      setSpecialNumber(specNums[0])

      /* GET PENDING STAMP ADDYS */
      const pendingKeys = globalState
        .filter((gs: { key: string }) => gs.key.startsWith('dGVtcF9'))
        .map((gs: { key: string; value: { uint: number; bytes: string } }) => {
          // Decode the key:
          // console.log(gs.key)
          // return {x:''}

          const decodedAddy = algosdk.encodeAddress(new Uint8Array(Buffer.from(gs.key, 'base64')).slice(16))
          // console.log(decodedAddy, gs.value)

          // Lets decode bytes:
          // console.log(Buffer.from(gs.value.bytes, 'base64').toString())
          // const test = new Uint8Array(Buffer.from(gs.value.bytes, "base64")).toString()
          // console.log(test)

          return {
            address: decodedAddy,
            nftid: clearBit(gs.value.uint, 31),
          }
        })

      // console.log(pendingKeys)
      setPendingStamps(pendingKeys)
    })
  }

  function handleConnectWalletClick() {
    connectWallet?.()
    // peraWallet
    //   .connect()
    //   .then((newAccounts) => {
    //     // Setup the disconnect event listener
    //     peraWallet.connector?.on('disconnect', handleDisconnectWalletClick)

    //     setAccountAddress(newAccounts[0])
    //     setUiState(UI_STATES.HOME)
    //   })
    //   .catch((err) => {
    //     console.log('handleConnectWalletClick Catch', err)
    //   })
    // // .reject((error) => {
    // //   // You MUST handle the reject because once the user closes the modal, peraWallet.connect() promise will be rejected.
    // //   // For the async/await syntax you MUST use try/catch
    // //   if (error?.data?.type !== 'CONNECT_MODAL_CLOSED') {
    // //     // log the necessary errors
    // //   }
    // // });
  }

  function handleDisconnectWalletClick() {
    disconnectWallet?.()
    // peraWallet.disconnect()
    // setAccountAddress(null)
    setUiState(UI_STATES.HOME)
    setUserBusTickets([])
  }

  const getDisplayInfo = () => {
    let custAddr = accountAddress || ''

    if (custAddr == '') custAddr = '[notcon]'

    return {
      addy: `${custAddr.substring(0, 4)}.${custAddr.substring(custAddr.length - 4, custAddr.length)}`,
      aBalM: accInfo?.amount,
      aBal: algosdk.microalgosToAlgos(accInfo?.amount || 0),
    }
  }

  // const startStamp_Click = () => {
  //   setShowStampModal(true)
  // }

  // eslint-disable-next-line spaced-comment
  /*************************************************************************************************\
   * 
   * Stamp events
   * 
  \*************************************************************************************************/
  const doStamp: (isSpecial: boolean) => void = async (isSpecial) => {
    // clean up ui:
    setResponseError(undefined)

    //  1. check available funds
    const mintCost = isSpecial ? MIN_COST_SPECIAL : MIN_COST_NORMAL
    const specNumb = isSpecial ? specialNumber : ''

    // console.log(mintCost)

    if (!accInfo) return

    const accBal = accInfo.amount
    // console.log(accBal, mintCost)

    // chec if enough balance:
    if (accBal < mintCost + 1000) {
      // Cannot afford!
      doShowNotEnoughBalance()
      setWaitMessage({ title: 'Not Enough Algos', msg: 'You do not have enough algo to stamp this ticket.' })
      // uiStateGoBack()
      return
    }

    // Check owns a token:

    // Start the process:
    // setUiState(UI_STATES.WAIT)
    changeUIState(UI_STATES.WAIT)
    axios.post(`https://appapi.craterdao.org/bus/create-asa/${accountAddress}`, { num: specNumb }).then((resp) => {
      // axios.post(`/bus/create-asa/${accountAddress}`, {num:specNumb}).then(resp => {
      console.log(resp)

      if (resp.data.error) {
        // alert(resp.data.error)
        setResponseError(resp.data)
        setUiState(UI_STATES.ERROR)
        return
      }

      const assetId = resp.data.assetid.replace(/[^0-9.]/g, '')
      console.log('ASSET ID', assetId)
      setTempTixAsid(assetId)
      // // completeStamping(assetId)
      // setStampTikWaiting(false)
      setUiState(UI_STATES.CLAIM_STAMP)
    })
  }

  const doClaim = async (nftAssetId: number) => {
    const isSpecial = isAssetSpecial(nftAssetId)
    const mintCost = isSpecial ? MIN_COST_SPECIAL : MIN_COST_NORMAL
    const accBal = accInfo.amount

    if (!nftAssetId || nftAssetId == 0) {
      console.log('No asset ID supplied to Claim')
      return
    }

    // do simple validate \\
    // chec if enough balance:
    if (accBal < mintCost + 1000) {
      // Cannot afford!
      doShowNotEnoughBalance()
      return
    }

    setStampTikWaiting(true)
    // setUiState(UI_STATES.WAIT)
    changeUIState(UI_STATES.WAIT)

    // This will continue the 2nd part of the claim process:
    console.log('nft asset id', nftAssetId)

    // const specialNumber = pickedNumber == -1 ? '' : pickedNumber.toString()
    // console.log('SENDING for spec num', specialNumber)

    const atc = new algosdk.AtomicTransactionComposer()
    const suggestedParams = await algodClient?.getTransactionParams().do()

    // get signer?
    const signer = await mySigner(accountAddress || '')
    // console.log(x)
    console.log('signer', signer)

    // Load up the SC ABI:
    const contract = new algosdk.ABIContract(busAbi)

    // Step 1 - Do optin:
    const optInTxn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
      from: accountAddress || '',
      to: accountAddress || '',
      assetIndex: parseInt(nftAssetId.toString()) as unknown as number,
      amount: 0,
      suggestedParams: suggestedParams as algosdk.SuggestedParams,
    })
    atc.addTransaction({ txn: optInTxn, signer: signer })

    // Step 2 - send bus token:
    const sendTokenTxn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
      from: accountAddress || '',
      suggestedParams: suggestedParams as SuggestedParams,
      to: defaultScAppID.addr,
      amount: 1,
      assetIndex: defaultBusTikID,
    })
    atc.addTransaction({ txn: sendTokenTxn, signer: signer })

    // Step 3 - send payment:
    const payTxn = algosdk.makePaymentTxnWithSuggestedParamsFromObject({
      from: accountAddress || '',
      suggestedParams: suggestedParams as algosdk.SuggestedParams,
      to: defaultScAppID.addr,
      amount: isSpecial ? 5001000 : 101000,
    })
    const payTxWs = { txn: payTxn, signer: signer }
    // atc.addTransaction(payTxWs);

    // Step 4 - Call out to the SC:
    atc.addMethodCall({
      appID: defaultScAppID.index,
      method: contract.getMethodByName('sendAssetToCaller'),
      methodArgs: [parseInt(nftAssetId.toString()), payTxWs],
      sender: accountAddress || '',
      signer: signer,
      suggestedParams: suggestedParams as SuggestedParams,
      appForeignAssets: [parseInt(nftAssetId.toString())],
      appAccounts: [accountAddress || ''],
    })

    try {
      const result = await atc.execute(algodClient as Algodv2, 2)
      console.log(result)

      // Now to reload the app details:
      // setUiState(UI_STATES.SUCCESS)
      changeUIState(UI_STATES.SUCCESS)
      loadAccountInfoUI()
      loadSCDetails()
      // setShowStampModal(false)
    } catch (err) {
      console.log('ERROR', err)

      // setUiState(UI_STATES.SUCCESS)
      uiStateGoBack()
      setCancelled(true)
      loadSCDetails()
    }
  }

  const mySigner: (addr: string) => Promise<TransactionSigner> = async (addr) => {
    return async (txnGroup: Transaction[], indexesToSign: number[]) => {
      const txsToSign = txnGroup.map((txn) => ({ txn, signers: [addr] }))
      return await peraWallet.signTransaction([txsToSign], addr)
    }
  }

  const isAssetSpecial = (nftId: number) => {
    const specNumsTest = [
      '000000',
      '000001',
      '000010',
      '000100',
      '001000',
      '010000',
      '100000',
      '100001',
      '001100',
      '000069',
      '000420',
      '690420',
      '123456',
      '111111',
      '654321',
      '123321',
      '110011',
      '111111',
      '222222',
      '333333',
      '444444',
      '555555',
      '666666',
      '777777',
      '888888',
      '999999',
    ]

    let isSpec = false
    appAccInfo['created-assets']
      .filter(
        (ass: { index: number; params: { 'unit-name': string } }) =>
          ass.index == nftId && ass.params['unit-name'] == 'BTIX',
      )
      .forEach((ass: { index: number; params: { url: string } }) => {
        // console.log(`${ass.index}: ${ass.params.url}`)

        const parts = ass.params.url.split('/')
        // console.log(parts)

        const tikNum = parts[parts.length - 1].split('.')[0]
        // console.log(tikNum)

        if (specNumsTest.includes(tikNum.padStart(6, '0'))) {
          console.log('SPECIAL:', tikNum)
          isSpec = true
        }
      })

    return isSpec
  }

  const doShowNotEnoughBalance = () => {
    console.log('not enough balance')
    setShowNotEnoughBal(true)
    setTimeout(() => setShowNotEnoughBal(false), 3000)
  }

  // https://stackoverflow.com/a/51067029/824260
  function clearBit(number: number, bitPosition: number) {
    const mask = ~(1 << bitPosition)
    return number & mask
  }

  // number of tokens to stamp:
  const numTokensAvailable = accInfo?.assets.reduce((acc: number, ass: accAssets) => {
    if (ass['asset-id'] == defaultBusTikID.toString()) acc += ass.amount
    return acc
  }, 0)












  // eslint-disable-next-line spaced-comment
  /*************************************************************************************************\
   * 
   * UI HERE
   * 
  \*************************************************************************************************/
  const StampChrome = (props: PropsWithChildren) => {
    const { children } = props
    return (
      <div
        className='min-h-screen bg-[#27aae1] relative
      '
      >
        <HowToStampModal />
        <TopNav />

        <div className=' mx-auto  flex flex-col justify-center items-center'>
          {/* ADDED UI HEADING AND DESCRIPTION START HERE */}

          <div className='page_container pt-14  pb-8 flex flex-col gap-8'>
            <div className=' text-white text-center w-full lg:w-[80%] md:mx-auto flex flex-col items-center justify-center space-y-6'>
              <h1 className='font-extrabold text-3.5xl leading-[45px] text-center md:text-4.5xl md:leading-[60px]'>
                Stamp, Validate <span className='inline md:block'>your CraterDAO Bus Ticket NFT</span>{' '}
              </h1>
              <p className='text-lg text-[#DDDDDD] text-center '>
                Experience the revolutionary CraterDAO Bus Ticket NFT, providing stamping and validation features for
                seamless and secure journeys into the blockchain-powered future.
              </p>
            </div>

            {/* <div className=' h-[200px]'>
              <img src={StampingArt} alt='StampingArt' className='w-full h-[700px]' />
            </div> */}
          </div>

          {/* ADDED UI HEADING & DESCRIPTION END HERE  */}

          <div className='mb-6'>{children}</div>
          <div className='fixed bottom-0 left-0 right-0 flex justify-between px-2 text-orange-300 text-sm bg-[#27aae1]'>
            <span>{getDisplayInfo().addy}</span>
            {/* <span>
              <a href='#' onClick={handleDisconnectWalletClick}>
                disconnect
              </a>
            </span> */}
            <span>{algosdk.microalgosToAlgos(getDisplayInfo().aBalM || 0)}A</span>
          </div>
        </div>
      </div>
    )
  }

  const showStateNotConnected = () => {
    return (
      <StampChrome>
        <div className='flex flex-col  justify-center items-center'>
          <ButtonConnectWallet />

          {/* <Button
            onClick={isConnectedToPeraWallet ? handleDisconnectWalletClick : handleConnectWalletClick}
            color='#27aae1'
            bgColor='white'
          >
            {isConnectedToPeraWallet ? 'Disconnect' : 'Connect to Pera Wallet'}
          </Button> */}

          <p className='mt-3 text-sm text-white text-center cursor-pointer' onClick={openModal}>
            How to stamp ticket
          </p>
        </div>
      </StampChrome>
    )
  }

  //  style={{backgroundImage:`url(${imgDroppingSoonBG})`, backgroundRepeat:'no-repeat', backgroundPosition:'top center'}}

  const showStateHome = () => {
    return (
      <StampChrome>
        <div className='flex flex-col  justify-center items-center'>
          {/* No tickets to stamp */}
          {accInfo &&
            accountAddress &&
            accInfo.assets.filter((ass: accAssets) => ass['asset-id'] == defaultBusTikID.toString() && ass.amount > 0)
              .length == 0 && (
              <div className='max-w-screen-sm p-4 m-auto bg-white shadow-lg rounded-xl dark:bg-gray-800'>
                <div className='w-full h-full text-center'>
                  <div className='flex flex-col justify-between h-full'>
                    <p className='px-6 py-2 text-gray-600 dark:text-gray-100 text-md'>
                      <div className='text-lg font-bold mb-2'>You have no tickets to stamp.</div>
                      <div className='mb-4'>
                        Head on over to <img src={imgAlgoGemLogo} className='inline' width={30} /> Algogems
                      </div>
                      <div>
                        <a
                          href='https://www.algogems.io/nft/1080669947/sale/1088043171'
                          target='_blank'
                          rel='noreferrer'
                          className='py-2 px-4  bg-[#27aae1] hover:bg-[#27aae1] focus:ring-[#27aae1] focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg'
                        >
                          CLICK HERE
                        </a>
                      </div>
                    </p>
                  </div>
                </div>
              </div>
            )}

          {/* Check for tickets to stamp */}
          {accInfo && accountAddress && numTokensAvailable > 0 && (
            <div
              className='text-center m-4 sm:m-0 py-20 lg:p-24 rounded-2xl'
              style={{ backgroundImage: `url(${imgRetroBG})` }}
            >
              <div className='text-center mt-6'>
                <div className='font-nebLogo text-5xl text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-600'>
                  YOU HAVE
                </div>
                <div className='font-typewriter text-8xl my-2'>{numTokensAvailable}</div>
                <div className='font-nebLogo text-3xl text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-orange-600'>
                  Ticket{numTokensAvailable > 1 ? 's' : ''} available to stamp!
                </div>
                <div className='mt-4 flex items-center'>
                  <div className='inset-x-0 max-w-max mx-auto'>
                    <Button color='#27aae1' bgColor='white' onClick={() => setUiState(UI_STATES.CHOOSE_NUMBER)}>
                      Start Stamping
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {!isConnectedToPeraWallet && <ButtonConnectWallet color='#27aae1' bgColor='white' />}
          

          <p className='mt-3 text-sm text-white text-center cursor-pointer' onClick={openModal}>
            How to stamp ticket
          </p>
        </div>

        {isConnectedToPeraWallet && userBusTickets && (
          <div className='my-6 mx-2'>
            <div className='font-semibold mb-4 text-white text-center'>My Bus Tickets</div>
            <div className='grid grid-cols-2 gap-2 lg:grid-cols-3 items-center text-center'>
              {userBusTickets.map((uNFT: UserNFTRec) => {
                // const val = algosdk.decodeAddress(uNFT.params.reserve)

                // const mhdigest = digest.create(mfsha2.sha256.code, val.publicKey)
                // console.log(mhdigest)

                // const rtnUrl = resolveProtocol(uNFT.params.url, uNFT.params.reserve)
                // console.log(rtnUrl)

                // let img = ''
                // axios.get(rtnUrl).then(resp => resp.data).then(data => {
                //   console.log(data)

                //   // pull the image from the
                //   const imgUrl = data.image.replace('ipfs://', '')
                //   console.log(imgUrl)

                //   img = `http://213.255.247.21:8080/ipfs/${imgUrl}`
                //   console.log(img)
                // })

                return (
                  <div className='max-w-xs'>
                    <IpfsImage url={uNFT.params.url} reserve={uNFT.params.reserve} />
                    {/* <img src={img} className='w-full h-auto max-w-xl rounded-lg' /> */}
                  </div>
                )
              })}
            </div>
          </div>
        )}
      </StampChrome>
    )
  }

  // const IpfsImage = (params:PropsWithoutRef<{url:string, reserve:string}>) => {
  //   const { url, reserve } = params
  //   const [loadedSrc, setLoadedSrc] = React.useState<string | null>(null);

  //   const rtnUrl = resolveProtocol(url, reserve)
  //   console.log(rtnUrl)

  //   let img = ''
  //   return axios.get(rtnUrl).then(resp => resp.data).then(data => {
  //     console.log(data)

  //     // pull the image from the
  //     const imgUrl = data.image.replace('ipfs://', '')
  //     console.log(imgUrl)

  //     img = `http://213.255.247.21:8080/ipfs/${imgUrl}`
  //     console.log(img)

  //     return ({img})
  //   })
  // }

  const showStateChooseNumber = () => {
    return (
      <StampChrome>
        <Modal
          setClose={() => {
            setUiState(UI_STATES.HOME)
          }}
        >
          <div className='text-white'>
            <div className='text-xl mb-3'>Start Stamping</div>
            <p>Stamp your ticket with a random number, or pick a Special number!</p>

            <div className='flex items-center px-5 py-4 my-2 text-blue-500 border border-blue-500 rounded-md jusitfy-between'>
              <div className='text-center w-full'>
                <Button onClick={() => doStamp(false)}>Any Number Thanks</Button>
              </div>
            </div>

            <div className='flex items-center px-5 py-4 my-2 text-blue-500 border border-blue-500 rounded-md jusitfy-between'>
              <div className='text-center w-full'>
                <select
                  className='block px-3 py-2 mb-4 text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm w-full focus:outline-none focus:ring-primary-500 focus:border-primary-500'
                  name='specNumbs'
                  onChange={(e) => setSpecialNumber(e.target.value)}
                  value={specialNumber}
                >
                  {specialNumbers.map((itm) => (
                    <option value={itm} key={itm}>
                      {itm}
                    </option>
                  ))}
                </select>
                <Button onClick={() => doStamp(true)} disabled={accInfo && accInfo?.amount < MIN_COST_SPECIAL}>
                  Special Number
                </Button>
              </div>
            </div>

            {responseError && (
              <div
                className='bg-pink-600 border-pink-800 text-white border-l-4 p-4 rounded-md overflow-hidden'
                role='alert'
              >
                <p className='font-bold'>{responseError?.name || ''}</p>
                <p>{responseError?.error || ''}</p>
              </div>
            )}

            {showNotEnoughBal && (
              <div className='bg-pink-600 border-pink-800 text-white border-l-4 p-4 rounded-md' role='alert'>
                <p className='font-bold'>Not enough balance</p>
                <p>You do not have enough balance to stamp this ticket.</p>
              </div>
            )}
          </div>
        </Modal>
      </StampChrome>
    )
  }

  const showStateChooseWait = () => {
    return (
      <StampChrome>
        <Modal>
          <div className='text-white my-5 items-center'>
            <div className='text-xl mb-3'>{waitMessage.title}</div>
            <div className='mb-3'>{waitMessage.msg}</div>
            <div className='h-10'>
              <BoxLoading style={{ inset: 'none', position: 'absolute', marginLeft: '35%' }} />
            </div>
          </div>
        </Modal>
      </StampChrome>
    )
  }

  const showStateClaimStamp = () => {
    return (
      <StampChrome>
        <Modal>
          <div className='text-white my-5 items-center'>
            <div className='text-xl mb-3'>Your Stamp is Ready!</div>
            <div className='mb-3'>Click below to get it stamped!</div>
            <div className='h-10'>
              <Button onClick={() => doClaim(pendingStamp?.nftid || tempTixAsid || 0)} className=''>
                Stamp it Now
              </Button>
            </div>
          </div>
        </Modal>
      </StampChrome>
    )
  }

  const showStampSuccess = () => {
    return (
      <StampChrome>
        <Modal>
          <div className='text-white my-5 items-center'>
            <div className='text-xl mb-3'>Success</div>
            <div className='mb-3'>
              <Button onClick={() => setUiState(UI_STATES.HOME)} className=''>
                Click to Reveal
              </Button>
            </div>
          </div>
        </Modal>
      </StampChrome>
    )
  }

  const showStatePending = () => {
    return (
      <StampChrome>
        <Modal>
          <div className='text-white my-5'>
            <div className='text-xl mb-3'>Pending Stamp</div>
            <p className='mb-3'>You have a stamp waiting for you!</p>
            <div className='items-center py-4'>
              <div className=''>
                <Button onClick={() => doClaim(pendingStamp?.nftid || 0)} className=''>
                  Claim it Now
                </Button>
              </div>
            </div>

            {showNotEnoughBal && (
              <div className='bg-pink-600 border-pink-800 text-white border-l-4 p-4 rounded-md' role='alert'>
                <p className='font-bold'>Not enough balance</p>
                <p>You do not have enough balance to stamp this ticket.</p>
              </div>
            )}

            {cancelled && (
              <div className='bg-pink-600 border-pink-800 text-white border-l-4 p-4 rounded-md' role='alert'>
                <p>The operation has been cancelled.</p>
              </div>
            )}
          </div>
        </Modal>
      </StampChrome>
    )
  }

  const showStateError = () => {
    return (
      <StampChrome>
        <Modal>
          <div className='text-white my-5'>
            <div className='grid grid-cols-2 gap-4'>
              <div>
                <div className='text-xl mb-3'>{waitMessage.msg || 'Something went wrong!'}</div>
                <div></div>
              </div>
              <div>
                <img src={imgBrokenRobot} />
              </div>
            </div>

            <Button onClick={() => uiStateGoBack()} className=''>
              Go Back
            </Button>

            {/* <div className='text-xl mb-3'>Pending Stamp</div>
            <p className='mb-3'>You have a stamp waiting for you!</p>
            <div className="items-center py-4">
              <div className="">
                <Button onClick={()=>doClaim(pendingStamp?.nftid||0)} className=''>Claim it Now</Button>
              </div>
            </div>

            {showNotEnoughBal && (
            <div className="bg-pink-600 border-pink-800 text-white border-l-4 p-4 rounded-md" role="alert">
                <p className="font-bold">
                    Not enough balance
                </p>
                <p>
                    You do not have enough balance to stamp this ticket.
                </p>
            </div>
            )} */}
          </div>
        </Modal>
      </StampChrome>
    )
  }

  const showStateReveal = () => {
    return (
      <StampChrome>
        <Modal>
          <div className='text-white my-5'>
            <div className='grid grid-cols-2 gap-4'>
              <div>
                <div className='text-xl mb-3'>Something went wrong!</div>
                <div></div>
              </div>
              <div>
                <img src={imgBrokenRobot} />
              </div>
            </div>
          </div>
        </Modal>
      </StampChrome>
    )
  }

  // return showStateError()

  // UPDATE: USING UI STATE
  switch (uiState) {
    case UI_STATES.NOT_CONNECTED:
      return showStateNotConnected()

    case UI_STATES.HOME:
      return showStateHome()

    case UI_STATES.CHOOSE_NUMBER:
      return showStateChooseNumber()
    case UI_STATES.WAIT:
      return showStateChooseWait()

    case UI_STATES.CLAIM_STAMP:
      return showStateClaimStamp()
    // case UI_STATES.CLAIM_WAIT:
    //   return showStateChooseWait()

    case UI_STATES.SUCCESS:
      return showStampSuccess()

    case UI_STATES.PENDING_STAMP:
      return showStatePending()

    case UI_STATES.ERROR:
      return showStateError()

    case UI_STATES.REVEAL:
      return showStateReveal()

    default:
      return <StampChrome>nothing</StampChrome>
  }

  // First, check for pending stamps:
  // const pendingStamp = pendingStamps.find(pa => pa.address == accountAddress||'')
  if (isConnectedToPeraWallet && pendingStamp != null) {
    return (
      <div className='flex flex-col h-screen justify-center items-center'>
        <Modal>
          <div className='text-white my-5'>
            <div className='text-xl mb-3'>Pending Stamp</div>
            <p className='mb-3'>You have a stamp waiting for you!</p>
            <div className='items-center py-4'>
              <div className=''>
                <Button onClick={() => doClaim(pendingStamp?.nftid || 0)} className=''>
                  Claim it Now
                </Button>
              </div>
            </div>

            {showNotEnoughBal && (
              <div className='bg-pink-600 border-pink-800 text-white border-l-4 p-4 rounded-md' role='alert'>
                <p className='font-bold'>Not enough balance</p>
                <p>You do not have enough balance to stamp this ticket.</p>
              </div>
            )}

            {stampTikWaiting && (
              <div>
                <div className='h-10'>
                  <BoxLoading style={{ inset: 'none', position: 'absolute', marginLeft: '35%' }} />
                </div>
              </div>
            )}
          </div>
        </Modal>
        <div className='fixed bottom-0 left-0 right-0 flex justify-between px-2 text-orange-300 text-sm'>
          <span>{getDisplayInfo().addy}</span>
          <span>{algosdk.microalgosToAlgos(getDisplayInfo().aBalM)}A</span>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className='flex flex-col h-screen justify-center items-center'>
        {/* <div className='relative top-0 left-0 right-0 flex justify-between px-2 text-orange-300 text-sm'>
        </div> */}

        <div>
          {!isConnectedToPeraWallet && (
            <Button onClick={isConnectedToPeraWallet ? handleDisconnectWalletClick : handleConnectWalletClick}>
              {isConnectedToPeraWallet ? 'Disconnect' : 'Connect to Pera Wallet'}
            </Button>
          )}

          {/* No tickets to stamp */}
          {accInfo &&
            accountAddress &&
            accInfo.assets.filter((ass: accAssets) => ass['asset-id'] == defaultBusTikID.toString() && ass.amount > 0)
              .length == 0 && (
              <div className='max-w-screen-sm p-4 m-auto bg-white shadow-lg rounded-xl dark:bg-gray-800'>
                <div className='w-full h-full text-center'>
                  <div className='flex flex-col justify-between h-full'>
                    <p className='px-6 py-2 text-gray-600 dark:text-gray-100 text-md'>
                      <div className='text-lg font-bold mb-2'>You have no tickets to stamp.</div>
                      <div className='mb-4'>
                        Head on over to <img src={imgAlgoGemLogo} className='inline' width={30} /> Algogems
                      </div>
                      <div>
                        <a
                          href='https://www.algogems.io/nft/1080669947/sale/1088043171'
                          target='_blank'
                          rel='noreferrer'
                          className='py-2 px-4  bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg'
                        >
                          CLICK HERE
                        </a>
                      </div>
                    </p>
                  </div>
                </div>
              </div>
            )}

          {/* Check for tickets to stamp */}
          {accInfo && accountAddress && numTokensAvailable > 0 && (
            <div className='text-center m-4 sm:m-0'>
              <div className='font-nebLogo text-5xl text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-600'>
                YOU HAVE
              </div>
              <div className='font-typewriter text-8xl my-2'>{numTokensAvailable}</div>
              <div className='font-nebLogo text-3xl text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-orange-600'>
                Ticket{numTokensAvailable > 1 ? 's' : ''} available to stamp!
              </div>
              <div className='mt-4 max-width-xs'>
                {/* <button onClick={()=>setShowStampModal(true)} className='py-2 px-4 bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg'>Start Stamping</button> */}
                <Button onClick={() => setShowStampModal(true)}>Start Stamping</Button>
              </div>
            </div>
          )}

          {showStampModal && (
            <Modal
              setClose={() => {
                setShowStampModal(false)
              }}
            >
              <div className='text-white'>
                <div className='text-xl mb-3'>Start Stamping</div>
                <p>Stamp your ticket with a random number, or pick a Special number!</p>

                <div className='flex items-center px-5 py-4 my-2 text-blue-500 border border-blue-500 rounded-md jusitfy-between'>
                  <div className='text-center w-full'>
                    <Button onClick={() => doStamp(false)}>Any Number Thanks</Button>
                  </div>
                </div>

                <div className='flex items-center px-5 py-4 my-2 text-blue-500 border border-blue-500 rounded-md jusitfy-between'>
                  <div className='text-center w-full'>
                    <select
                      className='block px-3 py-2 mb-4 text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm w-full focus:outline-none focus:ring-primary-500 focus:border-primary-500'
                      name='specNumbs'
                      onChange={(e) => setSpecialNumber(e.target.value)}
                    >
                      {specialNumbers.map((itm) => (
                        <option value={itm} key={itm}>
                          {itm}
                        </option>
                      ))}
                    </select>
                    <Button onClick={() => doStamp(true)} disabled={accInfo && accInfo?.amount < MIN_COST_SPECIAL}>
                      Special Number
                    </Button>
                  </div>
                </div>

                {responseError && (
                  <div
                    className='bg-pink-600 border-pink-800 text-white border-l-4 p-4 rounded-md overflow-hidden'
                    role='alert'
                  >
                    <p className='font-bold'>{responseError?.name || ''}</p>
                    <p>{responseError?.error || ''}</p>
                  </div>
                )}

                {showNotEnoughBal && (
                  <div className='bg-pink-600 border-pink-800 text-white border-l-4 p-4 rounded-md' role='alert'>
                    <p className='font-bold'>Not enough balance</p>
                    <p>You do not have enough balance to stamp this ticket.</p>
                  </div>
                )}
              </div>
            </Modal>
          )}
        </div>
        <div className='fixed bottom-0 left-0 right-0 flex justify-between px-2 text-orange-300 text-sm'>
          <span>{getDisplayInfo().addy}</span>
          <span>{algosdk.microalgosToAlgos(getDisplayInfo().aBalM || 0)}A</span>
        </div>
      </div>
    </>
  )
}

export default BusTix2
