import { Container, Nav, Navbar } from 'react-bootstrap'
import React from 'react'
import { Link } from 'react-router-dom'
import Logo from '../assets/YYcqAn6W_400x400-modified.png'
import Button from './Button'
import ButtonConnectWallet from './ButtonConnectWallet'

const TopNav = () => {

    return (
    <nav className='bg-white py-2'>
      <div className='page_container'>
        <div className='flex items-center justify-between'>
          <Link to='/' className='logo flex items-center space-x-2 cursor-pointer'>
            <div className='w-[38px]'>
              <img src={Logo} alt='Logo' className='w-full' />
            </div>

            <span className='text-2xl font-bold hidden md:block'>CraterDAO</span>
          </Link>
          <div className='w-auto'>
            <ButtonConnectWallet />

            {/* <Button
              onClick={isConnectedToPeraWallet ? handleDisconnectWalletClick : handleConnectWalletClick}
              color='white'
              bgColor='#27aae1'
            >
              {isConnectedToPeraWallet ? 'Disconnect' : 'Connect to Pera Wallet'}
            </Button> */}
          </div>
        </div>
      </div>
    </nav>
  )
}

export default TopNav
