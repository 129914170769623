import React, { useContext } from 'react'
import { useModalContext } from '../StateContext'

const HowToStampModal = () => {
  const { isModalOpen, closeModal } = useModalContext()

  return (
    isModalOpen && (
      <div className='absolute w-screen h-[100vh] '>
        <div className='bg-[#ffffff80] w-full h-full flex items-center justify-center'>
          <div className=' w-[90%] h-[70%] bg-white rounded-xl md:w-[700px] transition-transform duration-500 ease-in-out'>
            <div className='p-4 relative w-full h-full overflow-y-scroll'>
              <button className='absolute top-4 left-4 text-white font-semibold cursor-pointer' onClick={closeModal}>
                <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
                  <path
                    fill='#27aae1'
                    d='M19.8 4.2c-0.4-0.4-1-0.4-1.4 0L12 10.6 5.6 4.2c-0.4-0.4-1-0.4-1.4 0s-0.4 1 0 1.4L10.6 12l-6.4 6.4c-0.4 0.4-0.4 1 0 1.4 0.2 0.2 0.4 0.3 0.7 0.3s0.5-0.1 0.7-0.3L12 13.4l6.4 6.4c0.2 0.2 0.4 0.3 0.7 0.3s0.5-0.1 0.7-0.3c0.4-0.4 0.4-1 0-1.4L13.4 12l6.4-6.4C20.2 5.2 20.2 4.6 19.8 4.2z'
                  />
                </svg>
              </button>

              <div className='mt-7'>
                <p>
                  Make sure you have a ticket or purchase one{' '}
                  <a
                    href='http://www.algogems.io/nft/1080669947/sale/1088043171'
                    target='_blank'
                    rel='noopener noreferrer'
                    className='font-semibold text-secondary text-[#27aae1]'
                  >
                    here
                  </a>{' '}
                  for only 6 ALGO{' '}
                </p>
                <ol className='list-decimal my-5 pl-4'>
                  <li className='font-medium mb-1'>
                    Click the &quot;Connect to Pera Wallet&quot; button on the homepage (Only works for DEFLY wallets)
                  </li>
                  <li className='font-medium mb-1'>
                    On the next page, you should see a message stating that you have at least 1 tickect available. Click
                    &quot;Start Stamping&quot;
                  </li>
                  <li className='font-medium mb-1'>
                    You should now see a choice to choose either a randomized ticket number, or one of the 30 special
                    numbers for a fee of 5 ALGO
                  </li>
                  <li className='font-medium mb-1'>
                    After initializing, you should now see a button that says &quot;Claim Now&quot;
                  </li>
                  <li className='font-medium mb-1'>Approve transaction in your wallet</li>
                  <li className='font-medium mb-1'>Click on the button labeled &quot;Reveal&quot;</li>
                  <li className='font-medium mb-1'>
                    You should now see the new iteration of your Bus Ticket, both on the screen and in your wallet
                  </li>
                </ol>

                <p className='font-medium'>
                  Post a pic of your new ticket on twitter and tag{' '}
                  <span className='font-semibold text-secondary'>@Crater_DAO</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  )
}

export default HowToStampModal
