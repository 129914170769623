import React from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'

import Layout from './components/Layout'
// import Intro from './pages/Intro'
// import BusTix from './pages/BusTix'
import BusTix2 from './pages/BusTix2'
import BuyNeb from './pages/BuyNeb'
import Tools from './pages/Tools'

// import BusTixTimer from './pages/BusTixTimer'

// Set up routes:
const router = createBrowserRouter([
  {
    path: '/',
    element: <BusTix2 />,
    children: [],
  },
  {
    path: '/ticket-stamp',
    element: <BusTix2 />,
    children: [],
  },
  // {
  //   path: '/buy-neb',
  //   element: (
  //     <Layout>
  //       <BuyNeb />
  //     </Layout>
  //   ),
  // },
  {
    path: '/tools',
    element: (
      <Layout>
        <Tools />
      </Layout>
    ),
  },
])

function App() {
  // If still counting down, show countdown page:
  // if (Date.now() < 1681344000000)
  //   return <BusTixTimer />

  // App w-full h-full min-h-screen flex flex-col

  return (
    <div className='App w-full h-full min-h-screen flex flex-col bg-[#27aae1]'>
      <RouterProvider
        router={router}
        fallbackElement={
          <Layout>
            <BuyNeb />
          </Layout>
        }
      />
    </div>
  )
}

export default App
