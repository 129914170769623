import React, { PropsWithRef } from 'react'
import axios from 'axios'

import algosdk from 'algosdk'
import { CID } from 'multiformats/cid'
import * as digest from 'multiformats/hashes/digest'
import * as mfsha2 from 'multiformats/hashes/sha2'
// import { CIDVersion } from 'multiformats/types/src/cid'
// import * as XX from 'multiformats'
import { Version } from 'multiformats'

export const ARC3_NAME_SUFFIX = '@arc3'
export const ARC3_URL_SUFFIX = '#arc3'
export function resolveProtocol (url: string, reserveAddr: string): string {

  if (url.endsWith(ARC3_URL_SUFFIX))
      url = url.slice(0, url.length - ARC3_URL_SUFFIX.length)

  const chunks = url.split('://')
//   console.log('resolve protocol:', url)
//   console.log(chunks)

  // Check if prefix is template-ipfs and if {ipfscid:..} is where CID would normally be
  if (chunks[0] === 'template-ipfs' && chunks[1].startsWith('{ipfscid:')) {
      // Look for something like: template:ipfs://{ipfscid:1:raw:reserve:sha2-256} and parse into components
      chunks[0] = 'ipfs'
      const cidComponents = chunks[1].split(':')
      if (cidComponents.length !== 5) {
          // give up
          console.log('unknown ipfscid format')
          return url
      }
      const [, cidVersion, cidCodec, asaField, cidHash] = cidComponents

      // const cidVersionInt = parseInt(cidVersion) as CIDVersion
      if (cidHash.split('}')[0] !== 'sha2-256') {
          console.log('unsupported hash:', cidHash)
          return url
      }
      if (cidCodec !== 'raw' && cidCodec !== 'dag-pb') {
          console.log('unsupported codec:', cidCodec)
          return url
      }
      if (asaField !== 'reserve') {
          console.log('unsupported asa field:', asaField)
          return url
      }
      let cidCodecCode
      if (cidCodec === 'raw') {
          cidCodecCode = 0x55
      } else if (cidCodec === 'dag-pb') {
          cidCodecCode = 0x70
      }

      // get 32 bytes Uint8Array reserve address - treating it as 32-byte sha2-256 hash
      const addr = algosdk.decodeAddress(reserveAddr)
      const mhdigest = digest.create(mfsha2.sha256.code, addr.publicKey)

      // const cid = CID.create(parseInt(cidVersion) as CIDVersion, cidCodecCode as number, mhdigest)
      const cid = CID.create(parseInt(cidVersion) as Version, cidCodecCode as number, mhdigest)
    //   console.log('switching to id:', cid.toString())
      chunks[1] = cid.toString() + '/' + chunks[1].split('/').slice(1).join('/')
    //   console.log('redirecting to ipfs:', chunks[1])
  }

  // No protocol specified, give up
  if (chunks.length < 2) return url

  // Switch on the protocol
  switch (chunks[0]) {
      case 'ipfs': // Its ipfs, use the configured gateway
          // return conf[activeConf].ipfsGateway + chunks[1]
          // return 'http://213.255.247.21:8080/ipfs/' + chunks[1]
          return 'https://appapi.craterdao.org/ipfs/' + chunks[1]
          
      case 'https': // Its already http, just return it
          return url
      // TODO: Future options may include arweave or algorand
  }

  return url
}

const IpfsImage = (params:PropsWithRef<{url:string, reserve:string}>) => {
    const { url, reserve } = params
    const [loadedSrc, setLoadedSrc] = React.useState<string | undefined>(undefined);
    
    const rtnUrl = resolveProtocol(url, reserve)
    // console.log(rtnUrl)

    let img = ''
    axios.get(rtnUrl).then(resp => resp.data).then(data => {
    //   console.log(data)

      // pull the image from the 
      const imgUrl = data.image.replace('ipfs://', '')
    //   console.log(imgUrl)

      // img = `http://213.255.247.21:8080/ipfs/${imgUrl}`
      img = `https://appapi.craterdao.org/ipfs/${imgUrl}`
      
    //   console.log(img)

      // return ({img})
      setLoadedSrc(img)
    })

    return (
      <img src={loadedSrc} className='rounded-md' />
    )
  };

  export default IpfsImage;