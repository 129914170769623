import React, {PropsWithChildren, useState} from 'react'

import bgImg from '../assets/Cater-DAO-LogoOnly-reduced.png'


type ModalProps = {
    // show?: boolean,
    // onHide?: (params:unknown) => unknown | undefined;
    setClose?: () => void;
}

// const Index = () => {
const Index = (props: PropsWithChildren<ModalProps>) => {
    const { children, setClose } = props
//     const[show,setShow]=useState(showState)

//     React.useEffect(() => {
//         // show state changed externally:
//         setShow(showState)

//         if (!showState && onHide)
//             onHide(false)

//     }, [showState])
    
    return (
        <div>
            <div>
               <div className=" bg-gray-700 dark:bg-gray-900/50 transition duration-150 ease-in-out z-10 absolute top-0 right-0 bottom-0 left-0" id="modal">
                    {/* <div role="alert" className="container mx-auto w-11/12 md:w-2/3 max-w-lg"> */}
                    <div role="alert" className="flex flex-col h-screen justify-center  container mx-auto w-11/12 md:w-2/3 max-w-lg">
                        <div className="relative bg-white dark:bg-gray-800 dark:border-gray-700 shadow-md rounded border border-gray-400 overflow-hidden"
                            style={{backgroundImage:`url(${bgImg})`, backgroundRepeat:'no-repeat', backgroundPosition:'right -130px bottom -130px'}}
                        >
                            <div className='py-4 px-4 md:px-16 z-10'>
                                {children}
                            </div>
                            {/* <div className='float-right m-[-120px] mb-[-170px] z-0'>
                                <img src={bgImg} className='opacity-80' />
                            </div> */}
                            
                            
                            {/* <div className="w-full flex justify-center text-green-400 mb-4">
                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-circle-check" width={56} height={56} viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" />
                                    <circle cx={12} cy={12} r={9} />
                                    <path d="M9 12l2 2l4 -4" />
                                </svg>
                            </div>
                            <h1 className="text-center text-gray-800 dark:text-gray-100 font-lg font-bold tracking-normal leading-tight mb-4">Payment Processing Successful</h1>
                            <p className="mb-5 text-sm text-gray-600 dark:text-gray-400 text-center font-normal">Thank you for your payment. An automated payment receipt will be sent to your email. Check the action below for more details.</p>
                            
                            <div className="flex items-center justify-center w-full">
                                <button className="focus:outline-none transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 rounded text-white px-4 sm:px-8 py-2 text-xs sm:text-sm">Manage Plan</button>
                                <button className="focus:outline-none ml-3 bg-gray-100 dark:bg-gray-700 dark:border-gray-700 dark:hover:bg-gray-600 transition duration-150 text-gray-600 dark:text-gray-400 ease-in-out hover:border-gray-400 hover:bg-gray-300 border rounded px-8 py-2 text-sm" onClick={()=>setClose()} >
                                    Cancel
                                </button>
                            </div> */}
                            <div className="cursor-pointer absolute top-0 right-0 mt-4 mr-5 text-gray-400 hover:text-gray-600 dark:hover:text-gray-500 transition duration-150 ease-in-out" onClick={()=>setClose?.()} >
                                <svg xmlns="http://www.w3.org/2000/svg" aria-label="Close" className="icon icon-tabler icon-tabler-x" width={20} height={20} viewBox="0 0 24 24" strokeWidth="2.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" />
                                    <line x1={18} y1={6} x2={6} y2={18} />
                                    <line x1={6} y1={6} x2={18} y2={18} />
                                </svg>
                            </div>

                            
                        </div>
                    </div>
                </div>
                {/* <div className="w-full flex justify-center py-12" id="button">
                    <button className="focus:outline-none mx-auto transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 rounded text-white px-4 sm:px-8 py-2 text-xs sm:text-sm" onClick={()=>setClose()}>
                        Open Modal
                    </button>
                </div> */}
            </div>
        </div>
    );
};
export default Index;
