/* eslint-disable react/jsx-key */
import React from 'react'

// import MyAlgoWallet from '@randlabs/myalgo-connect'

import { PeraWalletConnect } from '@perawallet/connect'

import Button from '../components/Button';
import algosdk, { Algodv2 } from 'algosdk';

import { useLocalStorage } from 'usehooks-ts'
import AlgodClient from 'algosdk/dist/types/client/v2/algod/algod';


type MasterAccInfoType = {
  address: string,
  mn: string
} | null

// Testing master acc (Testnet)
const defaultMasterAcc:MasterAccInfoType = {
  address:  'AFWO3KZZPPGSION2KDBFM3DML3ERR7UKUF7O7HUIQMVFYW5N244SWU3OE4',
  mn:       'distance upper media unlock across surge settle confirm frost luggage indoor distance decade brief blouse route load save giant love palm key nest absent stable'
}

// Master acc on Mainnet:
// const defaultMasterAcc:MasterAccInfoType = {
//   address:  '',
//   mn:       ''
// }

const defaultBusTikID = 167821197
// const defaultBusTikID = 175

const testContractInfo = {
  id: 210330632,
  Addr: '7LALDJ5D7YRXUZCR5A6P643HMGHSQ7FDNIU7YOENSYBKSR3COPBX5A6YCU'
}

const Tools = () => {
  const [ masterAccInfo, setMasterAccInfo ] = useLocalStorage<MasterAccInfoType>('masterAccInfo', defaultMasterAcc)
  const [ masterBalance, setMasterBalance ] = React.useState<number>(0)


  const [accountAddress, setAccountAddress] = React.useState<string | null>(null);
  const isConnectedToPeraWallet = !!accountAddress;

  const peraWallet = new PeraWalletConnect();

  const [ accInfo, setAccInfo ] = React.useState<any>()
  const [ algodClient, setAlgodClient ] = React.useState<Algodv2>()

  const [ waiting, setWaiting ] = React.useState<boolean>(false)
  


  React.useEffect(() => {
    // Re-connect an older Pera wallet session:
    // Reconnect to the session when the component is mounted
    peraWallet.reconnectSession().then((accounts) => {
      // Setup the disconnect event listener
      peraWallet.connector?.on('disconnect', handleDisconnectWalletClick);

      if (peraWallet.isConnected && accounts.length) {
        setAccountAddress(accounts[0]);
      }
    }).catch(error=> {
      console.log(error);
    })

  }, []);

  
  React.useEffect(() => {
    if (accountAddress){
      // const token = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      // const server = 'http://127.0.0.1';
      // const port = 4001;
      
      // // AlgoNode:
      // const token = '';
      // const server = 'https://testnet-api.algonode.network/';
      // const port = 443;

      // const client = new algosdk.Algodv2(token, server, port);

      // setAlgodClient(client)

      console.log('getting client status')

      loadAccountDetails()

      // ;(async () => {
      //   // console.log(await client.status().do());

      //   // check balance;
      //   const acctInfo = await client.accountInformation(accountAddress).do();
      //   console.log(`Account balance: ${acctInfo.amount} microAlgos`);
      //   console.log(acctInfo)

      //   setAccInfo(acctInfo)

      // })().catch((e) => {
      //   console.log(e);
      // });
    }
  }, [accountAddress])


  const loadAccountDetails = () => {
    let client

    if (!algodClient){
      // const token = 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
      // const server = 'http://127.0.0.1';
      // const port = 4001;

      // AlgoNode:
      const token = '';
      const server = 'https://testnet-api.algonode.network/';
      const port = 443;

      client = new algosdk.Algodv2(token, server, port);

      setAlgodClient(client)

      console.log('getting client status')
    } else {
      client = algodClient
    }

    ;(async () => {
      // console.log(await client.status().do());

      // check balance;
      if (accountAddress){
        const acctInfo = await client.accountInformation(accountAddress).do();
        console.log(`Account balance: ${acctInfo.amount} microAlgos`);
        console.log(acctInfo)

        setAccInfo(acctInfo)
      }

    })().catch((e) => {
      console.log(e);
    });
  }

  if (masterAccInfo?.mn) {
    // Dump out address:
    console.log('Decoding mnemonic', masterAccInfo?.mn||'')
    const pkey = algosdk.mnemonicToSecretKey(masterAccInfo?.mn||'');
    console.log(pkey)

    // const account = algosdk.generateAccount();
    // console.log('Account Address: ', account);

    // const mn = algosdk.secretKeyToMnemonic(account.sk);
    // console.log('Account Mnemonic: ', mn);

    // impose abstract glove route approve ask chest control unit miracle also chicken theme object alone special cute peace pepper pelican amount more cool ability lock
    // PG7OJB6AZI3Z4QMT2OXQOC2FF4Y4XLVMO2AC4ILQATIP53TWNLU2K7N7MA
  }


  // Get master acc balance:
  if (masterAccInfo && algodClient){
    
    ;(async () => {
      // check balance:
      const acctInfo = await algodClient.accountInformation(masterAccInfo.address).do();
      setMasterBalance(acctInfo.amount)

      // Dump out address:
      // const pkey = algosdk.mnemonicToSecretKey(masterAccInfo?.mn||'');
      // console.log(pkey)
      // let buf = Buffer.from(pkey., 'base64')[32 :]


      // pk = base64.b64decode(private_key)[32 :]
      // address = encoding.encode_address(pk)

    })().catch((e) => {
      console.log(e);
    });
  }

  

  // const btnConnectClick = () => {
  //   // myAlgoConnector?.connect().then(acc => {
  //   //   console.log(acc)
  //   //   setAccounts(acc)
  //   // })

  //   // if (!connector?.connected) {
  //   //   // create new session
  //   //   // connector?.createSession();
  //   //   connector?.connect()
  //   // }
  // };

  function handleConnectWalletClick() {
    peraWallet
      .connect()
      .then((newAccounts) => {
        // Setup the disconnect event listener
        peraWallet.connector?.on('disconnect', handleDisconnectWalletClick);

        setAccountAddress(newAccounts[0]);
      })
      // .reject((error) => {
      //   // You MUST handle the reject because once the user closes the modal, peraWallet.connect() promise will be rejected.
      //   // For the async/await syntax you MUST use try/catch
      //   if (error?.data?.type !== 'CONNECT_MODAL_CLOSED') {
      //     // log the necessary errors
      //   }
      // });
  }

  function handleDisconnectWalletClick() {
    peraWallet.disconnect();
    setAccountAddress(null);
  }

  const doOptinClick: () => void = async () => {
    // THIS IS FROM PERA WALLET SITE: https://docs.perawallet.app/references/pera-connect#methods

    // Do the opt in
    const suggestedParams = await algodClient?.getTransactionParams().do();
    console.log(suggestedParams)

    // make tx objects (first optin, second send):
    const optInTxn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
      from: accountAddress || '',
      to: accountAddress || '',
      assetIndex: defaultBusTikID,
      amount: 0,
      suggestedParams: suggestedParams as algosdk.SuggestedParams
    })
    // const optInTxn2 = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
    //   from: FROM_ADDRESS,
    //   to: FROM_ADDRESS,
    //   assetIndex: ASSET_ID,
    //   amount: 0,
    //   suggestedParams
    // });

    // sign it:
    // const signedTxn = ptxn.signTxn(acct.privateKey);
    const singleTxnGroups = [{txn: optInTxn, signers: [accountAddress||'']}];

    try {
      const signedTxn = await peraWallet.signTransaction([singleTxnGroups]);
      
      if (algodClient){
        const {txId} = await algodClient.sendRawTransaction(signedTxn).do();
        console.log(txId)

        if (txId){
          alert('OptIn Successful: ' + txId)

          window.setTimeout(loadAccountDetails, 1500)
        }
      }

    } catch (error) {
      console.log('Couldn\'t sign Opt-in txns', error);
    }


  }

  const createTixASA: () => void = async () => {
    // get default params:
    const suggestedParams = await algodClient?.getTransactionParams().do();
    console.log(suggestedParams)

    // make tx objects (first optin, second send):
    const createTixTxn = algosdk.makeAssetCreateTxnWithSuggestedParamsFromObject({
      assetName: 'Btik',
      from: masterAccInfo?.address || '',
      manager: masterAccInfo?.address || '',
      reserve: masterAccInfo?.address || '',
      total: 3000,
      unitName: 'BTIK',
      suggestedParams: suggestedParams as algosdk.SuggestedParams,
      decimals: 0,
      defaultFrozen: false
    })

    // const singleTxnGroups = [{txn: createTixTxn, signers: [accountAddress||'']}];

    try {
      const pkey = algosdk.mnemonicToSecretKey(masterAccInfo?.mn||'');

      // const signedTxn = await peraWallet.signTransaction([singleTxnGroups]);
      // const signedTxn = await createTixTxn.signTransaction();
      const signedTxn = createTixTxn.signTxn(pkey.sk);
      console.log(signedTxn)

      if (algodClient){
        const {txId} = await algodClient.sendRawTransaction(signedTxn).do();
        console.log(txId)
        
        // if (txId){
        //   alert('Create Tickets Successful')

        //   window.setTimeout(loadAccountDetails, 1500)
        // }
      }

    } catch (error) {
      console.log('Couldn\'t sign Opt-in txns', error);
    }
  }

  const createMasterAcc: () => void = async () => {
    const account = algosdk.generateAccount();
    console.log('Account Address: ', account);

    const mn = algosdk.secretKeyToMnemonic(account.sk);
    console.log('Account Mnemonic: ', mn);

    setMasterAccInfo({
      address: account.addr,
      mn: mn
    })

  }


  const giveTicketToken: () => void = async () => {
    // send a token from master acc to user acc:

    // get default params:
    const suggestedParams = await algodClient?.getTransactionParams().do();
    console.log(suggestedParams)

    // make tx objects (first optin, second send):
    const sendTixTxn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
      from: masterAccInfo?.address || '',
      to: accountAddress || '',
      suggestedParams: suggestedParams as algosdk.SuggestedParams,
      assetIndex: defaultBusTikID,
      amount: 1
    })

    try {
      const pkey = algosdk.mnemonicToSecretKey(masterAccInfo?.mn||'');

      // const signedTxn = await peraWallet.signTransaction([singleTxnGroups]);
      // const signedTxn = await createTixTxn.signTransaction();
      const signedTxn = sendTixTxn.signTxn(pkey.sk);
      console.log(signedTxn)

      if (algodClient){
        setWaiting(true)
        const {txId} = await algodClient.sendRawTransaction(signedTxn).do();
        console.log(txId)

        await algosdk.waitForConfirmation(algodClient, txId, 2)
        
        if (txId){
          // alert('Ticket Given: ' + txId)

          window.setTimeout(loadAccountDetails, 1000)
          setWaiting(false)
        }
      }

    } catch (error) {
      console.log('Couldn\'t sign Opt-in txns', error);
    }

  }

  const testWithdrawFromContract = async () => {
    console.log('testWithdrawFromContract')

    // get default params:
    const suggestedParams = await algodClient?.getTransactionParams().do();
    console.log(suggestedParams)

    // make tx objects (first optin, second send):
    const sendTxn = algosdk.makePaymentTxnWithSuggestedParamsFromObject({
      from: testContractInfo.Addr,
      to: accountAddress || '',
      suggestedParams: suggestedParams as algosdk.SuggestedParams,
      amount: 10000000
    })

    try {
      const pkey = algosdk.mnemonicToSecretKey(masterAccInfo?.mn||'');

      // const signedTxn = await peraWallet.signTransaction([singleTxnGroups]);
      // const signedTxn = await createTixTxn.signTransaction();
      const signedTxn = sendTxn.signTxn(pkey.sk);
      console.log(signedTxn)

      if (algodClient){
        setWaiting(true)
        const {txId} = await algodClient.sendRawTransaction(signedTxn).do();
        console.log(txId)

        await algosdk.waitForConfirmation(algodClient, txId, 2)
        
        if (txId){
          // alert('Ticket Given: ' + txId)

          window.setTimeout(loadAccountDetails, 1000)
          setWaiting(false)
        }
      }

    } catch (error) {
      console.log('Couldn\'t sign Opt-in txns', error);
    }
  }

  
  // Look for bustix on account:
  let accBusTixCount = 0
  if (accInfo){
    const btx = accInfo.assets.find((ass:accAssets) => ass['asset-id']==defaultBusTikID.toString())
    if (btx)
      accBusTixCount = btx.amount
  }




  return (
    <>
    {!masterAccInfo && (
      <div className="bg-white mx-auto max-w-5xl shadow-lg rounded-lg overflow-hidden p-4 mt-4">
        <div className='text-lg font-bold'>No master acc defined</div>
        <p>Click below to create one</p>

        <div className='max-w-lg'>
          <Button onClick={createMasterAcc}>Create Master Acc</Button>
        </div>

      </div>
    )}
    {masterAccInfo && (
      <div className="bg-white mx-auto max-w-5xl shadow-lg rounded-lg overflow-hidden p-4 mt-4">
        <div className='text-lg font-bold'>Master account</div>
        
        <ul>
          <li><span className='inline-block w-32'>Address:</span><span>{masterAccInfo.address}</span></li>
          <li><span className='inline-block w-32'>Mn:</span><span>{masterAccInfo.mn}</span></li>
          <li><span className='inline-block w-32'>Balance:</span><span>{algosdk.microalgosToAlgos(masterBalance)} algo</span></li>
          <li><span className='inline-block w-32'>BusTIK ID:</span><span><a href='https://app.dappflow.org/explorer/asset/167821197/transactions' target='_blank' rel='noreferrer'>{defaultBusTikID}</a></span></li>
        </ul>

      </div>
    )}


      <div className="bg-white mx-auto max-w-5xl shadow-lg rounded-lg overflow-hidden pt-2 mt-4">
        
        <div className='flex items-center justify-center'>
          <div className='block w-60'>
            <Button
              onClick={
                isConnectedToPeraWallet ? handleDisconnectWalletClick : handleConnectWalletClick
              }>
              {isConnectedToPeraWallet ? 'Disconnect' : 'Connect to Pera Wallet'}
            </Button>
          </div>
        </div>
        
        <div className="sm:flex sm:items-center px-6 py-4">
          {/* {accountAddress} */}
          {/* {!accounts && <Button onClick={btnConnectClick}>Connect Wallet</Button>} */}
        </div>

        <div className='px-6 py-4'>
          {accInfo && accountAddress && (
            <>
              <div className="flex items-center">
                <ul>
                  <li><span className='inline-block w-32'>Address:</span><span>{accountAddress}</span></li>
                  <li><span className='inline-block w-32'>Amount:</span><span>{algosdk.microalgosToAlgos(accInfo.amount)}</span> algos</li>
                  <li><span className='inline-block w-32'>Status:</span><span>{accInfo.status}</span></li>
                  <li><span className='inline-block w-32'>Opted In:</span><span>{accInfo.assets.map((ass:accAssets) => <span>{ass['asset-id']}, </span>)}</span>
                  {!accInfo.assets.find((ass:accAssets) => ass['asset-id']==defaultBusTikID.toString()) && (
                    <span className='inline-block w-32'>
                      <Button onClick={doOptinClick}>Opt In</Button>
                    </span>
                  )}
                  </li>
                  <li><span className='inline-block w-32'>Tix Tokens:</span><span>{accBusTixCount}</span></li>
                </ul>
              </div>
              {/* {accBusTixCount == 0 && ( */}
                <div className='flex items-center justify-center'>
                  <div className='block w-60'>
                    <Button onClick={giveTicketToken}>
                      <div className='inline-flex items-center'>
                        {waiting && (
                        <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        )}
                        Give a ticket token
                      </div>
                    </Button>
                  </div>
                </div>
              {/* )} */}
            </>
          )}
        </div>
        
        
      </div>

      <div className="bg-white mx-auto max-w-5xl shadow-lg rounded-lg overflow-hidden p-4 mt-4">
        <div className='text-lg font-bold'>Assets</div>

        <div className='grid gap-2'>
          <div>
            <Button onClick={createTixASA}>Create Tix ASA 3k</Button>
          </div>
          <div>
            <Button onClick={testWithdrawFromContract}>Test withdraw from Contract</Button>
          </div>
        </div>
      </div>
    </>
  )
}

export default Tools

type accAssets = {
  'asset-id': string
}