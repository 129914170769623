import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { ModalProvider } from './StateContext'

// import { WalletProvider } from './components/WalletProvider'
import { WalletProvider } from './hooks/useWallet'

import { Buffer } from 'buffer'
window.Buffer = Buffer

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <WalletProvider>
      <ModalProvider>
        <App />
      </ModalProvider>
    </WalletProvider>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
