import { ReactNode } from 'react'
import { Container } from 'react-bootstrap'
import TopNav from './TopNav'

type LayoutProps = {
  children: ReactNode
}

const Layout = ({ children }: LayoutProps) => {
  return (
    <>
      <TopNav />

      <Container className='mx-auto max-w-[900px] min-h-screen'>{children}</Container>
    </>
  )
}

export default Layout
